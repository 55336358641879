import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Account as AccountView,
  Acquisition as AcqView,
  Dashboard as DashboardView,
  Funding as FundingView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Org as OrgView,
  Person as PersonView,
  Search as SearchView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AcqView}
        exact
        layout={MainLayout}
        path="/acquisition/:acqid"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={FundingView}
        exact
        layout={MainLayout}
        path="/funding/:funid"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={OrgView}
        exact
        layout={MainLayout}
        path="/org/:orgid"
      />
      <RouteWithLayout
        component={PersonView}
        exact
        layout={MainLayout}
        path="/person/:personid"
      />
      <RouteWithLayout
        component={SearchView}
        exact
        layout={MainLayout}
        path="/search"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

const PreviewRoutes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/not-found"
      />
      <RouteWithLayout
        component={OrgView}
        exact
        layout={MainLayout}
        path="/org/:orgid"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default {
  Routes,
  PreviewRoutes
}
