import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { 
  Drawer,
  Grid
} from '@material-ui/core';
import { OrgView, PersonView } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100%',
    marginTop: 58,
    height: 'calc(100% - 58px)'
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    flexBasis: '0',
  },
  grid: {
  }
}));

const Searcher = props => {
  const { 
    open, 
    variant, 
    onClose, 
    className, 
    searchQuery,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={'persistent'}
      transitionDuration={0}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Grid item xs={12}>
          {process.env.REACT_APP_APP_ENV !== 'preview' && <OrgView onClose={onClose} query={searchQuery.query}/>}          
        </Grid>
        <Grid item xs={12}>
          {process.env.REACT_APP_APP_ENV !== 'preview' && <PersonView onClose={onClose} query={searchQuery.query}/>}
        </Grid>
      </div>
    </Drawer>
  );
};

Searcher.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  searchQuery: PropTypes.object,
};

export default Searcher;
