import React , {useState, useEffect} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import palette from 'theme/palette';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { apiEndpoint, fetchData } from 'helpers';
import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const MonthlyFundings = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  /////////////////////////
  const url = apiEndpoint + '/monthly/funding/2020'
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  const labels = items.data.map(d => moment().month(d.month-1).format("MMMM"))
  const dataA = items.data.map(d => d.total )

  const data = {
    labels: labels,
    datasets: [{
      label: 'Funding',
      backgroundColor: palette.primary.main,
      data: dataA
    }]
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {isError && <div>Error: unexpected error occured!!</div> }
      {isLoading && <div>Loading...</div> }
      <CardHeader
        action={
          <Button
            size="small"
            variant="text"
          >
            TESTESTSTES <ArrowDropDownIcon />
          </Button>
        }
        title="Monthly Fundings"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

MonthlyFundings.propTypes = {
  className: PropTypes.string
};

export default MonthlyFundings;
