import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { apiEndpoint, abbreviateNum, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const TotalAquisitionAmounts = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  /////////////////////////
  const url = apiEndpoint + '/total/acquisition/2020'
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              AQUISITION AMOUNTS
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
            >
              {isError && <div>Error: unexpected error occured!!</div> }
              {isLoading && <div>Loading...</div> }
              {items.data.map(d => (
                '￥' + abbreviateNum(d.total)                  
              ))}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalAquisitionAmounts.propTypes = {
  className: PropTypes.string
};

export default TotalAquisitionAmounts;
