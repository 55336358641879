import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { 
  List,
  ListItem
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

const OrgView = props => {
  const { 
    className, 
    query,
    onClose,
    ...rest
  } = props;
  const classes = useStyles();

  /////////////////////////
  const url = apiEndpoint + '/search/org/' + query
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.icon}><BusinessIcon />Organization</div>
      <List>
      {query !== '' && isError && <div>Error: unexpected error occured!!</div> }
      {isLoading && <div>Loading...</div> }
      {items.data.map(d => (              
        <ListItem key={d.a_org_id} button divider alignItems='center' component={RouterLink} to={"/org/"+d.a_org_id} onClick={onClose}>
          {d.a_org_company_name}
        </ListItem>
      ))}
      </List>
      {query !== '' && items.count === 0 && <div>No result...</div>}
    </div>
  )
}

OrgView.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string,
  onClose: PropTypes.func,
};

export default OrgView