import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  cell: {
    width:'20%'
  },
  cellLarge: {
    width:'60%'
  }
}));

const Team = props => {

  const { 
    className,
    count,
    persons,
    ...rest 
  } = props;
  const classes = useStyles()

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          avatar={
            <div className={classes.icon}><PeopleIcon /></div>
          }
          title="Team"
          titleTypographyProps={{ fontSize: "TODO" }}
        />
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell} align="center">役職</TableCell>
                <TableCell className={classes.cell} align="center">氏名</TableCell>
                <TableCell className={classes.cellLarge} align="center">プロフィール</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {persons.map(p => (  
              <TableRow>
                <TableCell align="center">{p["B-2_per_business_title"]}</TableCell>
                <TableCell align="center">{p["B-1_per_name"]}</TableCell>
                <TableCell >{p["B-1_per_profile"]}</TableCell>
              </TableRow>
              ))}  
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
}

Team.propTypes = {
  className: PropTypes.string,
  count: PropTypes.string,
  persons: PropTypes.array,
};

export default Team;
