import React, {  useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const TotalAquisitions = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  /////////////////////////
  const url = apiEndpoint + '/total/acquisition/2020'
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              AQUISITIONS
            </Typography>
            <Typography variant="h3">
              {isError && <div>Error: unexpected error occured!!</div> }
              {isLoading && <div>Loading...</div> }
              {items.data.map(d => (
                d.count
              ))}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <BusinessIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  );
};

TotalAquisitions.propTypes = {
  className: PropTypes.string
};

export default TotalAquisitions 
