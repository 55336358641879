import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  colum2cell: {
    width: '40%'
  },
  colum2cellLarge: {
    width: '60%'
  }  
}));

const Overview = props => {

  const { className, data, ...rest } = props;
  const classes = useStyles()

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          avatar={
            <div className={classes.icon}><BusinessIcon /></div>
          }
          title="Overview"
          titleTypographyProps={{ fontSize: "TODO" }}
        />
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.colum2cell}>会社名</TableCell>
                <TableCell className={classes.colum2cellLarge}>{data.a_org_company_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.colum2cell}>住所</TableCell>
                <TableCell className={classes.colum2cellLarge}>{data.a_org_address1_todouhuken} {data.a_org_addresss2_sicyouson} {data.a_org_address3_rest}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.colum2cell}>カテゴリ</TableCell>
                <TableCell className={classes.colum2cellLarge}>{data.a_org_company_category}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.colum2cell}>創立</TableCell>
                <TableCell className={classes.colum2cellLarge}>{data.a_org_foundation_date}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
}

Overview.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array
};

export default Overview;
