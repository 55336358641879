import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { 
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Paper,
  Input
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    borderRadius: '4px',
    alignItems: 'center',
    display: 'flex',
    flexBasis: 200
  },
  text: {
    fontSize: 25,
    fontWeight: "bold"
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    color: theme.palette.text.secondary,
    fontSize: 'large',
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const Topbar = props => {
  const { 
    className,
    onSidebarOpen,
    style,
    onSearcherOpen,
    onChange,
    onClose,
    ...rest
  } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  if (process.env.REACT_APP_APP_ENV === 'preview') {
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/pbdb.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
  }else{
    return (
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Toolbar>
          <RouterLink to="/">
            <img
              alt="Logo"
              src="/images/logos/pbdb.png"
            />
          </RouterLink>
          <IconButton onClick={onClose} aria-label="delete" className={classes.margin} size="small">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <Paper
            {...rest}
            className={clsx(classes.search, className)}
            style={style}
            square
          >
            <SearchIcon/>
            <Input
              {...rest}
              className={classes.input}
              disableUnderline
              onChange={onChange}
              onClick={onSearcherOpen}
              placeholder="Search..."
            />
          </Paper>
          <div className={classes.flexGrow} />
          <Hidden mdDown>
            <IconButton color="inherit">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
            >
              <InputIcon />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  style: PropTypes.object,
  onSearcherOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default Topbar;
