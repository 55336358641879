import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  colum2cell: {
    width: '40%'
  },
  colum2cellLarge: {
    width: '60%'
  },
  cell: {
    width:'20%'
  },
  cellLarge: {
    width:'60%'
  }
}));

const Service = props => {

  const { 
    className,
    svsc,
    ...rest 
  } = props;
  const classes = useStyles()

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          avatar={
            <div className={classes.icon}><CategoryIcon /></div>
          }
          title="Service"
          titleTypographyProps={{ fontSize: "TODO" }}
        />
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell} align="center">サービス名</TableCell>
                <TableCell className={classes.cell} align="center">タグ</TableCell>
                <TableCell className={classes.cellLarge} align="center">詳細</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {svsc.map(s => (
              <TableRow>
                <TableCell className={classes.cell}>
                  <Link key={s.id} href={s.c_service_link} color="inherit">{s.c_service_name}</Link>
                </TableCell>
                <TableCell className={classes.cell}>{s.tags || "-"}</TableCell>
            <TableCell className={classes.cellLarge}>{s.c_service_note || "-"}</TableCell>
              </TableRow>
            ))}
            </TableBody>
            </Table>
        </CardContent>
      </Card>
    )
}

Service.propTypes = {
  className: PropTypes.string,
  svsc: PropTypes.array,
};

export default Service;
