import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { 
  Card,
  CardContent,
  Grid, 
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableRow,
  Link} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

const Person = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  const { personid } = props.match.params

  /////////////////////////
  const url = apiEndpoint + '/person/' + personid
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <div className={classes.root}>
      <Grid
        lg={12}
        md={12}
        xl={12}
        xs={12}
        item={true}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <div className={classes.icon}><PersonIcon />Person</div>
          <CardHeader/>
          <CardContent {...rest} className={clsx(classes.content, className)} >
            <Table>
              <TableBody>
                {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
                {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
                {items.count === 0 && <TableRow><TableCell>Person Not Found</TableCell></TableRow>}
                {items.data.map( d => (
                  <Fragment key={d.b_per_id}>
                    <TableRow><TableCell>Name</TableCell><TableCell>{d.b_per_name}</TableCell></TableRow>
                    <TableRow><TableCell>Company Name</TableCell><TableCell>{d.a_org_company_name}</TableCell></TableRow>
                    <TableRow><TableCell>Category</TableCell><TableCell>{d.a_org_company_category}</TableCell></TableRow>
                    <TableRow><TableCell>Tags</TableCell><TableCell>{d.a_org_tag}</TableCell></TableRow>
                    <TableRow><TableCell>Profile</TableCell><TableCell>{d.b_per_profile}</TableCell></TableRow>
                    <TableRow>
                      <TableCell>Twitter</TableCell>
                      <TableCell><Link href={d.b_per_twitter}>{d.b_per_twitter}</Link></TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default Person;
