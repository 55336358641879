import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
  LatestFundings,
  LatestAquisitions,
  TotalAquisitions,
  TotalAquisitionAmounts,
  TotalFundings,
  TotalFundingAmounts,
  MonthlyFundings,
  LatestNews
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalAquisitions />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalAquisitionAmounts />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalFundings />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalFundingAmounts />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <LatestNews />
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <LatestAquisitions />
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
        >
          <LatestFundings />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <MonthlyFundings />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
