import { Link as RouterLink } from 'react-router-dom';
import React, {  useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Typography,
  Link
} from '@material-ui/core';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const FundingsNew = props => {

  const { className, ...rest } = props;
  const classes = useStyles()

  /////////////////////////
  const url = apiEndpoint + '/discover/funding'
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          action={
            <Button
              color="primary"
              size="small"
              variant="outlined"
            >
              New entry
            </Button>
          }
          title="Latest Fundings"
        />

        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Org Name</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Date
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
                {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
                  {items.data.map(d => (
                    <TableRow
                      hover
                      key={d.c_fin_id}
                    >
                      <TableCell>
                        <RouterLink to={"/org/"+d.a_org_id}>{d.a_org_company_name}</RouterLink>
                      </TableCell>
                      <TableCell>{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(d.c_fin_funding_amount)}</TableCell>
                      <TableCell>
                        {moment(d.c_fin_funding_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                      <Typography className={classes.root}>
                        <Link href={d.c_fin_source} >
                          Link
                        </Link>
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
        </CardActions>
      </Card>
    )
}

FundingsNew.propTypes = {
  className: PropTypes.string
};


export default FundingsNew;
