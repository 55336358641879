import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { 
  Card,
  CardContent,
  Grid, 
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link } from '@material-ui/core';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Acquisition = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  const { acqid } = props.match.params

  /////////////////////////
  const url = apiEndpoint + '/acquisition/' + acqid
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <div className={classes.root}>
      <Grid
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardHeader
            title="Acquisition"
          />
          <CardContent className={classes.content}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
                {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
                {items.data.map( d => (
                  <Fragment>
                    <TableRow><TableCell>Company Name</TableCell><TableCell>{d.a_org_company_name}</TableCell></TableRow>
                    <TableRow><TableCell>Category</TableCell><TableCell>{d.a_org_company_category}</TableCell></TableRow>
                    <TableRow><TableCell>Tags</TableCell><TableCell>{d.a_org_tag}</TableCell></TableRow>
                    <TableRow>
                      <TableCell>Service Name</TableCell>
                      <TableCell><Link href={d.a_org_service_link} onclick={preventDefault}>{d.a_org_service_link}</Link></TableCell>
                    </TableRow>
                    <TableRow><TableCell>Outline</TableCell><TableCell>{d.a_org_service_outline}</TableCell></TableRow>
                    <TableRow><TableCell>Investment</TableCell><TableCell>{d.a_org_investment_area}</TableCell></TableRow>
                    <TableRow><TableCell>Board Member</TableCell><TableCell>{d.a_org_board_member}</TableCell></TableRow>
                    <TableRow><TableCell>Founded</TableCell><TableCell>{d.a_org_foundation_date}</TableCell></TableRow>
                    <TableRow><TableCell>Country</TableCell><TableCell>{d.a_org_country}</TableCell></TableRow>
                    <TableRow><TableCell>Address1</TableCell><TableCell>{d.a_org_address1_todouhuken}</TableCell></TableRow>
                    <TableRow><TableCell>Address2</TableCell><TableCell>{d.a_org_addresss2_sicyouson}</TableCell></TableRow>
                    <TableRow><TableCell>Address3</TableCell><TableCell>{d.a_org_address3_rest}</TableCell></TableRow>
                    <TableRow><TableCell>Stock</TableCell><TableCell>{d.a_org_capital_stock}</TableCell></TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

const preventDefault = (e) => {
  window.open(e.data, '_blank');
  e.preventDefault();
}

export default Acquisition;
