import { Link as RouterLink } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { 
  Card,
  CardContent,
  Grid, 
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { apiEndpoint,abbreviateNum, fetchData } from 'helpers';
import {
  Overview,
  Service,
  Finance,
  Team,
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  colum2cell: {
    width: '40%'
  },
  colum2cellLarge: {
    width: '60%'
  },
  cell: {
    width:'20%'
  },
  cellLarge: {
    width:'60%'
  },
  title: {
    padding: '5px'
  }
}));

const Org = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  const { orgid } = props.match.params

  /////////////////////////
  const url = apiEndpoint + '/org/' + orgid
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <div className={classes.root}>
      {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
      {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
      {items.count === 0 && <TableRow><TableCell>Organization Not Found</TableCell></TableRow>}
      {items.data.map( d => (
      <Grid
        container
        spacing={4}
      >
        <Grid
          lg={12}
          md={12}
          xl={12}
          xs={12}
          item={true}
        >
          <Overview data={d}></Overview>
        </Grid>
        <Grid
          lg={12}
          md={12}
          xl={12}
          xs={12}
          item={true}
        >
          <Service svsc={d.svc.data} ></Service>
        </Grid>
        <Grid
          lg={12}
          md={12}
          xl={12}
          xs={12}
          item={true}
        >
          <Finance fundings={d.funding.data}></Finance>
        </Grid>
        <Grid
          lg={12}
          md={12}
          xl={12}
          xs={12}
          item={true}
        >
          <Team
            count={d.persons.count}
            persons={d.persons.data}
          >
          </Team>
        </Grid>
      </Grid>
      ))}
    </div>
  );
};

export default Org;
