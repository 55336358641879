import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { 
  Card,
  CardContent,
  Grid, 
  Table,
  CardHeader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link } from '@material-ui/core';
import { apiEndpoint, abbreviateNum, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Funding = props => {

  const { className, ...rest } = props;
  const classes = useStyles();

  const { funid } = props.match.params

  /////////////////////////
  const url = apiEndpoint + '/funding/' + funid
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
    <div className={classes.root}>
      <Grid
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardHeader
            title="Funding"
          />
          <CardContent className={classes.content}>
            <Table>
              {/* <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
                {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
                {items.data.map( d => (
                  <List component="nav" aria-label="main">
                    <ListItem><ListItemText primary="●Company Name" secondary={d.a_org_company_name || "--"}/></ListItem>
                    <ListItem><ListItemText primary="●Tags" secondary={d.org.count > 0 && d.org.data[0].a_org_tag != "" ? d.org.data[0].a_org_tag : "--"}/></ListItem>
                    <ListItem><ListItemText primary="●Service" secondary={
                      d.svcs.data.map(e => (
                        <div>
                          <a href={e.f_ser_url}>{e.f_ser_name}</a>
                        </div>
                      ))
                    }/></ListItem>
                    <ListItem><ListItemText primary="●Corporate Link" secondary={
                      <a href={d.org.data[0].a_corporate_link}>{d.org.data[0].a_corporate_link || "--"}</a>
                    }/></ListItem>
                    <ListItem><ListItemText primary="●Service Detail" secondary={"--"}/></ListItem>
                    <ListItem><ListItemText primary="●Funding Date" secondary={d.c_fin_funding_date || "-"}/></ListItem>
                    <ListItem><ListItemText primary="●Funding Ammount" secondary={
                      d.c_fin_funding_amount != "" && 
                        <span>
                        {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(d.c_fin_funding_amount)}
                        </span>
                    }/></ListItem>
                    <ListItem><ListItemText primary="●Invester" secondary={
                      d.invs.data.map(e => (
                        <div>
                          {e.a_corporate_link != "" &&
                            <a href={e.a_corporate_link}>{e.a_org_company_name}</a>
                          }
                          {e.a_corporate_link == "" &&
                            <span>{e.a_org_company_name}</span>
                          }
                        </div>
                      ))
                    }/></ListItem>
                    <ListItem><ListItemText primary="●Round" secondary={d.c_fin_series}/></ListItem>
                    <ListItem button onClick={(e) => window.location.href=d.c_fin_source}><ListItemText primary="News" secondary={d.c_fin_source_title} /></ListItem>
                  </List>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

const preventDefault = (e) => {
  window.open(e.data, '_blank');
  e.preventDefault();
}

export default Funding;
