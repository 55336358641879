import { Link as RouterLink } from 'react-router-dom';
import React, {  useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Typography,
  Link
} from '@material-ui/core';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestAquisitions = props => {

  const { className, ...rest } = props;
  const classes = useStyles()

  /////////////////////////
  const url = apiEndpoint + '/discover/acquisition'
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          title="Latest Aquisition"
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Aquired</TableCell>
                    <TableCell>Aquiring</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Date
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    {/* <TableCell>Type</TableCell> */}
                    <TableCell>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
                {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
                  {items.data.map(d => (
                    <TableRow
                      hover
                      key={d.d_acq_id}
                    >
                      <TableCell>
                        <RouterLink to={"/org/"+d.a_acquired_org_ID}>{d.a_acquired_company_name}</RouterLink>
                      </TableCell>
                      <TableCell>
                        <RouterLink to={"/org/"+d.a_acquiring_org_ID}>{d.a_acquiring_company_name}</RouterLink>
                      </TableCell>
                      <TableCell>{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(d.d_acq_amount)}</TableCell>
                      <TableCell>
                        {moment(d.d_acq_MA).format('DD/MM/YYYY')}
                      </TableCell>
                      {/* <TableCell>{d.d_acq_type}</TableCell> */}
                      <TableCell>
                      <Typography className={classes.root}>
                        <Link href={d.d_acq_source} >
                          Link
                        </Link>
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
        </CardActions>
      </Card>
    )
}

LatestAquisitions.propTypes = {
  className: PropTypes.string
};

export default LatestAquisitions;

///////////////// if wanna
// const callAPI = () => {
//   const [data, setData] = useState({ count:0 , data:{} });
//   const [url, setUrl] = useState(
//     'http://localhost:3000/stubs/funding.json',
//   );
//   const [isLoading, setIsLoading] = useState(false);
//   const [isError, setIsError] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setIsError(false);
//       setIsLoading(true);

//       try {
//         const result = await Axios(url)
//         setData(result.data);
//       } catch (error) {
//         setIsError(true);
//       }
//       setIsLoading(false);
//     };

//     fetchData();
//   }, [url])

//   return [{data, isLoading, isError}, setUrl]
// }
///////////////////////