import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import { abbreviateNum } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  colum2cell: {
    width: '40%'
  },
  colum2cellLarge: {
    width: '60%'
  }  
}));

const Finance = props => {

  const { 
    className,
    fundings,
    fundingDate,
    fundingSeries,
    fundingAmount,
    fundingCompanies,
    ...rest 
  } = props;
  const classes = useStyles()

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          avatar={
            <div className={classes.icon}><MoneyIcon /></div>
          }
          title="Finance"
          titleTypographyProps={{ fontSize: "TODO" }}
        />
        <CardContent className={classes.content}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">調達日</TableCell>
                <TableCell align="center">シリーズ</TableCell>
                <TableCell align="center">金額</TableCell>
                <TableCell align="center">調達元</TableCell>
                <TableCell align="center">ニュースソース</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fundings.map((row) => (
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    <Typography variant="button" display="block" gutterBottom>
                      {row.d_finance_date}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" display="block" gutterBottom>
                      {row.d_finance_series && row.d_finance_series || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" display="block" gutterBottom>
                      ￥{row.d_finance_amount && abbreviateNum(row.d_finance_amount) || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.d_finance_investor.data.map((r) => (
                        <span>{r.a_org_company_name}<br/></span>
                      ))}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="button" display="block" gutterBottom>
                      <Link key={row.d_finance_id} href={row.d_finance_news_url} color="inherit" rel="noreferrer" target="_blank">{row.d_finance_news_title}</Link>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
}

Finance.propTypes = {
  className: PropTypes.string,
  fundings: PropTypes.array,
  fundingDate: PropTypes.string,
  fundingSeries: PropTypes.string,
  fundingAmount: PropTypes.string,
  fundingCompanies: PropTypes.array
};

export default Finance;
