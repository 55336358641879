import Axios from 'axios';

const fetchData = (url, setData, setIsLoading, setIsError) => {
  return () => {
    async function fd () {
      setIsError(false);
      setIsLoading(true);
      try {  
        const result = await Axios(url)
        setData(result.data);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    }
    fd()  
  }
}

export default fetchData