import { Link as RouterLink } from 'react-router-dom';
import React, {  useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { apiEndpoint, fetchData } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestNews = props => {

  const { className, ...rest } = props;
  const classes = useStyles()

  /////////////////////////
  const url = apiEndpoint + '/discover/funding'
  const [items, setData] = useState({ count:0 , data:[] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(fetchData(url, setData, setIsLoading, setIsError), [url])
  /////////////////////////

  return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          title="Latest News"
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                </TableHead>
                <TableBody>
                {isError && <TableRow><TableCell>Error: unexpected error occured!!</TableCell></TableRow> }
                {isLoading && <TableRow><TableCell>Loading...</TableCell></TableRow> }
                  {items.data.map(d => (
                    <TableRow
                      hover
                      key={d.c_fin_id}
                    >

                        <TableCell>
                          {d.c_fin_funding_date}
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.root}>
                            <RouterLink to={"/funding/"+d.c_fin_id}>{d.c_fin_source_title}</RouterLink>
                          </Typography>                
                        </TableCell>
                        <TableCell>
                          <RouterLink to={"/org/"+d.a_org_id}>{d.a_org_company_name}</RouterLink>
                        </TableCell>
                    </TableRow>                    
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
        </CardActions>
      </Card>
    )
}

LatestNews.propTypes = {
  className: PropTypes.string
};

export default LatestNews;
